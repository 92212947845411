@import '../../shared/variables';

.sidebar {
    position: fixed;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $bg3;
    color: $text;
    font-size: 1.5rem;
    > * {
        padding: 1rem;
        &:not(:first-child) {
            margin-top: auto;
        }
    }
    nav {
        display: flex;
        flex-direction: column;
        a {
            margin: 0.5rem 0;
        }
    }
}

@media screen and (max-width: 799px) {
    .sidebar {
        display: none;
    }
}

.langSwitch {
    display: flex;
    justify-content: space-between;
}

button {
    background-color: $text;
    color: $bg3;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    &:disabled {
        background-color: $highlight;
        color: $text;
    }
}