@import './shared/variables';

* {
    color: $text;
    font-family: monospace;
}
body {
    margin: 0;
    background-color: $bg1;
    font-size: 1.25rem;
    [lang=de] {
        display: none;
    }
}

h1 {
    color: $text;
    .highlight {
        color: $highlight;
    }
}

h3 {
    margin-bottom: 0;
}

a,
a:link,
a:visited,
a:hover,
a:active {
    display: inline-block;
    color: $text;
}

.hidden {
    display: none;
}

.d-flex {
    display: flex;
    > div {
        align-self: center;
    }
}