@import '../../../shared/variables';

.accordionItem {
	border-bottom: 1px solid $highlight;
	> div {
		border-top: 1px dashed $text;
		&.hidden {
			display: none;
		}
	}
}
h3 {
	margin: 0;
	padding: 0.5rem 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	text-decoration: none;
}
